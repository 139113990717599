import * as React from "react"
import { PopupButton } from "@typeform/embed-react"
import ITHealthcareBackground from "../images/services/ithealthcare-background.jpg"
import NetworkBackground from "../images/services/networkoptimization-background.jpg"


export default function ContactFooter() {
  return (
    <div className="grid gap-6 md:grid-cols-2">
        <div
      className="w-full rounded-lg shadow-xl py-24 mx-auto px-6 sm:py-12 md:py-36 md:px-10"
      style={{ backgroundImage: `url(${NetworkBackground})` }}
    >
      <div className="sm:text-left lg:text-left">
        <h1 className="text-2xl tracking-tight text-center font-heading font-extrabold text-white sm:text-4xl md:text-4xl lg:text-4xl">
          Have a question?
        </h1>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
          <div className="m-auto">
            <PopupButton
              id="JUEN3hai"
              className="m-auto sr-onlyshadow w-60 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-insight bg-white hover:bg-insight-darker md:py-4 md:text-lg md:px-10"
            >
              Reach Out
            </PopupButton>
          </div>
        </div>
      </div>
    </div>
    <div
      className="w-full rounded-lg shadow-xl py-24 mx-auto px-6 sm:py-12 md:py-36 md:px-10"
      style={{ backgroundImage: `url(${ITHealthcareBackground})` }}
    >
      <div className="sm:text-left lg:text-left">
        <h1 className="text-2xl tracking-tight text-center font-heading font-extrabold text-white sm:text-4xl md:text-4xl lg:text-4xl">
          Get a free consultation!
        </h1>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
          <div className="m-auto">
            <PopupButton
              id="ILHyUo06"
              className="m-auto sr-onlyshadow w-60 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-insight bg-white hover:bg-insight-darker md:py-4 md:text-lg md:px-10"
            >
              Get Started
            </PopupButton>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
