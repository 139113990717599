import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactFooter from "../components/contact-footer"
import AboutBackground from "../images/about.jpg"
import Placeholder from "../images/placeholder.png"
import Ethan from "../images/about/ethan.jpg"

const About = () => (
  <Layout>
    <Seo title="Web Development" />
    <div
      className="rounded-xl shadow-xl py-24 mx-auto px-6 sm:py-12 md:py-36 md:px-10"
      style={{
        backgroundImage: `url(${AboutBackground})`,
        backgroundSize: `cover`,
      }}
    >
      <div className="sm:text-left lg:text-left">
        <h1 className="text-4xl tracking-tight font-heading font-extrabold text-white sm:text-5xl md:text-6xl lg:text-8xl">
          Who are we?
        </h1>
      </div>
    </div>
    <div className="mt-16 mb-24 md:mx-20 ">
      <div
        id="cyber"
        className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div>
            <p className="text-xl max-w-6xl">
              i360 is a group of dedicated IT professionals, that will work
              tirelessly for your company. We leverage a combined experience of
              80+ years in the IT industry while continuing to learn the best
              new products on the market in order to keep your company running
              at optimum efficiency now - and in the future.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="mb-24 md:mx-20 ">
    <div>
        <div>
          <h2 className="mb-10 text-4xl text-center">Our Team</h2>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-10">
        <div className="p-6 shadow-xl border-2 border-gray-200 rounded-xl col-start-2" alt="">
          <h3 className=" text-xl">Douglas Wharton</h3>
          <p>Co-Owner</p>
          <p>Infrastructure Specialist</p>
        </div>
        <div className="p-6 shadow-xl border-2 border-gray-200 rounded-xl col-start-3" alt="">
          <h3 className=" text-xl">Vivek S Bagal</h3>
          <p>Co-Owner</p>
          <p>Application Specialist</p>
        </div>
      </div>
    </div>
    <ContactFooter />
  </Layout>
)

export default About
